<template>
    <div class="finding-item" :class="[index % 2 === 0 ? 'odd' : 'evn', `finding-item-${index}`]">
        <img class="img" :class="[`img${index + 1}`, `finding-item-${index}-ani`]" :src="img" alt="" />
        <div class="info" :class="[`finding-item-${index}-ani`]">
            <div class="poppins title">{{ data.title }}</div>
            <div class="descs">
                <div class="desc" v-for="(desc, descIndex) in data.descs" :key="descIndex">{{ desc }}</div>
            </div>
            <div class="list" v-if="data.list">
                <div class="list-item" v-for="(list, listIndex) in data.list" :key="listIndex">
                    <img class="list-item-icon" :src="list.icon" alt="" />
                    <div class="list-item-name">{{ list.name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        index: {
            type: Number,
            default: 0,
        },
        data: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        img() {
            let { currentLang, data } = this;
            const buildForOverseas = process.env.VUE_APP_BUILD_FOR === 'evelabinsight';
            if (data.imgId === 3) {
                return require(`@assets/images/solutions/finding/${data.imgId}_${currentLang}.png`);
            }
            return require(`@assets/images/solutions/finding/${buildForOverseas ? 'evelabinsight/' : ''}${data.imgId}.png`);
        },
    },
    mounted() {
        this.mxAnimation({
            handle: `.finding-item-${this.index}-ani`,
            triggerElement: `.finding-item-${this.index}`,
            triggerHook: 0.7,
        });
    },
};
</script>

<style lang="scss">
.finding-item {
    @media only screen and (min-width: 769px) {
        display: flex;
        align-items: center;
        margin-bottom: 80px;

        &.odd {
            .info {
                margin-left: auto;
            }
        }
        &.evn {
            flex-direction: row-reverse;
            .info {
                margin-right: auto;
            }
        }
        .info {
            width: 460px;
            .title {
                font-size: 24px;
                line-height: 28.8px;
                font-weight: 500;
                margin-bottom: 10px;
            }
            .desc {
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 10px;
                position: relative;
                &:last-of-type {
                    margin-bottom: 0;
                }
                &:before {
                    content: '';
                    width: 4px;
                    height: 4px;
                    border-radius: 4px;
                    position: absolute;
                    left: -14px;
                    top: 8px;
                    background: #000;
                }
            }
            .list {
                display: flex;
                flex-wrap: wrap;
                padding-top: 18px;
                &-item {
                    width: 45%;
                    padding-right: 5%;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    line-height: 20px;
                    margin: 10px 0;
                    &-icon {
                        width: 35px;
                        height: 35px;
                        margin-right: 10px;
                    }
                }
            }
        }
        .img {
            display: block;
            flex-shrink: 0;
            &1 {
                width: 421px;
                height: 440px;
            }
            &2 {
                width: 560px;
                height: 330px;
            }
            &3 {
                width: 517px;
                height: 385px;
            }
            &4 {
                width: 600px;
                height: 365px;
                transform: translateX(60px);
            }
            &5 {
                width: 514px;
                height: 457px;
                transform: translateX(-20px);
            }
        }
    }
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 0.6rem;

        .img {
            display: block;
            width: 100%;
            margin-bottom: 0.2rem;
        }
        .info {
            .title {
                font-size: 0.2rem;
                line-height: 0.23rem;
                font-weight: 600;
                margin-bottom: 0.2rem;
            }
            .desc {
                margin-bottom: 0.1rem;
                position: relative;
                font-size: 0.13rem;
                line-height: 0.18rem;

                &:last-of-type {
                    margin-bottom: 0;
                }

                &:before {
                    content: '';
                    position: absolute;
                    width: 0.04rem;
                    height: 0.04rem;
                    border-radius: 0.04rem;
                    background: #000;
                    left: -0.12rem;
                    top: 0.07rem;
                }
            }
            .list {
                display: flex;
                flex-wrap: wrap;
                font-size: 0.13rem;
                line-height: 0.18rem;
                padding-top: 0.18rem;
                &-item {
                    width: 50%;
                    box-sizing: border-box;
                    display: flex;
                    align-items: center;
                    margin: 0.08rem 0;

                    &-icon {
                        width: 0.3rem;
                        height: 0.3rem;
                        margin-right: 0.1rem;
                    }
                }
            }
        }
    }
}
</style>
